import cardioIcon from '../../images/category_icons/cardio.svg';
import strengthIcon from '../../images/category_icons/strength.svg';
import stabilityIcon from '../../images/category_icons/stability.svg';
// import longevityIcon from '../../images/category_icons/longevity.svg';

export const content = {
  longevity: {
    title: "Longevity Score",
    // icon: longevityIcon,
    description: "Your overall health and fitness score",
  },
  cardio: {
    title: "Cardiorespiratory fitness",
    icon: cardioIcon,
    description: "How efficiently your body uses oxygen during exercise",
    // Add more content structure as needed
  },
  strength: {
    title: "Strength foundations",
    icon: strengthIcon,
    description: "Building and maintaining functional strength",
    // Add more content structure as needed
  },
  stability: {
    title: "Stability & mobility",
    icon: stabilityIcon,
    description: "Foundation for movement quality and injury prevention",
    // Add more content structure as needed
  }
};
