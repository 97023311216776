import React from 'react';
import './Widget.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import PercentileTag from './PercentileTag';
// import EffortIndicator from './Indicator';
import Metric from './Metric';

import { EffortIndicator } from './Indicator';
import { ConfidenceIndicator } from './Indicator';



export const Widget = ({ children, className, title, icon }) => {
  return (
    <div className={`widget ${className || ''}`}>
      {title && (
        <div className="widget-title">
          <div className="title-content">
            {icon && <img src={icon} alt={`${title} icon`} className='widget-icon' />}
            <span>{title}</span>
          </div>
        </div>
      )}
      <div className="widget-content">
        {children}
      </div>
    </div>
  )
};

export const WidgetHome = ({ 
  title, 
  variant, 
  icon, 
  percentile, 
  effortBars = 0, 
  effortTitle = 'Effort' 
}) => {
  const hasNoMetric = percentile === undefined || percentile === null;

  return (
    <div className={`widget widget-home ${variant || ''} ${hasNoMetric ? 'no-metric' : ''}`}>
      <div className="widget-title">
        <div className="title-content">
          <img src={icon} alt={`${title} icon`} className='widget-icon' />
          <span>{title}</span>
        </div>
        <PercentileTag percentile={percentile} variant={variant} />
      </div>
      <div className="widget-content">
        <div className="effort-wrapper">
          <EffortIndicator 
            title={effortTitle} 
            filledBars={effortBars} 
            variant={variant} 
          />
        </div>
      </div>
      {!hasNoMetric && <FontAwesomeIcon icon={faChevronRight} className="chevron-icon-home" />}
    </div>
  );
};

export const WidgetDimension = ({ 
    title, 
    variant, 
    icon, 
    percentile, 
    metrics, 
    precision,
    effortBars,
    effortTitle
}) => {
console.log('effort title widget-dimension:', effortTitle);
  return (
    <div className={`widget widget-dimension ${variant || ''}`}>
      <div className="widget-title">
        <div className="title-content">
          {icon && <img src={icon} alt={`${title} icon`} className='widget-icon' />}
          <span>{title}</span>
        </div>
        {percentile && <PercentileTag percentile={percentile} variant={variant} />}
      </div>
      <div className="widget-content">
        {metrics && (
          <div className="metrics-container">
            {effortTitle && (
              <div className="metric">
                <EffortIndicator 
                  title={effortTitle} 
                  filledBars={effortBars} 
                  variant={variant} 
                />
              </div>
            )}
            {metrics.map((metric, index) => {
              if (metric.label === "Confidence") {
                return (
                  <div key={index + 1} className="metric">
                    <div className="metric-title">{metric.label}</div>
                    <ConfidenceIndicator precision={precision} />
                  </div>
                );
              }
              return (
                <Metric
                  key={index + 1}
                  title={metric.label}
                  value={metric.value}
                  unit={metric.unit}
                />
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};

