import React from 'react';
import MetricGraph from '../components/MetricGraph';

const mockMetric = {
  metric_code: "v02_max",
  real_name: "VO2 Max",
  percentile: 0.75,
};

const mockUserData = {
  metrics: {
    v02_max: {
      measure_batch_13_10_2024: {
        measures: {
          main_measure: {
            value: 42,
            percentile: 0.75,
            timestamp: "2024-01-01T00:00:00Z"
          }
        }
      }
    }
  }
};

const knowledgeContent = {
  general: {
    info: {
      title: "General Knowledge",
      description: "Basic information about the application"
    },
    subsections: {
      app: `# Application Guide

      <span className="knowledge-quote">
      The data demonstrating the effectiveness of exercise on lifespan are as dose to irrefutable as one can find in all human biology.
      </span>

      Exercise has the greatest power to determine how you will live out the rest of your life. 
      
      It delays the onset of chronic diseases, pretty much across the board, but it is also amazingly effective at extending and improving healthspan. 

Not only does it reverse physical decline, which I suppose is somewhat obvious, but it can slow or reverse cognitive decline as well. (It also has benefits in terms of emotional health, although those are harder to quantify.)


Even a little bit of daily activity is much better than nothing. Going from zero weekly exercise to just ninety minutes per week can reduce your risk of dying from all causes by 14 percent. It's very hard to find a drug that can do that.


Study after study has found that regular exercisers live as much as a decade longer than sedentary people. 

(The US government's physical activity guidelines suggest that "active adults" engage in at least 30 minutes of
"moderate-intensity aerobic activity?' five times per week (or 150 minutes in total). This is to be supplemented with two days of strength training, targeting "all major muscle groups."

      `,

      "life score": `
      
      
      `,
      theory: (props) => (
        `# Theory Section
        
        Your VO2 Max is <span style="color: blue">${props.vo2max}</span> which indicates...
        
        Your HRV is <span style="color: green">${props.hrv}</span> and your resting heart rate is ${props.restingHr}.`
      ),
      advanced: (props) => (
        `# Advanced Topics
        
        Here's a **formatted** section with your <span style="color: red; font-weight: bold">VO2 Max: ${props.vo2max}</span>
        
        * List item 1
        * List item 2`
      )
    }
  },
  cardiorespiratory: {
    info: {
      title: "Cardiorespiratory Fitness",
      description: "How efficiently your body uses oxygen. Measured using VO2 Max, it's the single most powerful marker for longevity.",
    },
    subsections: {
      "cardio score": (props) => ({
        content: `
        
### Understanding your Cardio Score: <span className="interpolated-data">85%</span>

You're projected to have healthy levels of cardio fitness for <span className="interpolated-data">85%</span> of your life. That's <span className="interpolated-data">65 years</span>, out of <span className="interpolated-data">84</span>, the life expectancy for your age and gender group.

That number is obtained by applying a yearly decline to your current v02 max levels of <span className="interpolated-data">42 ml/kg/min</span>. With this approach, you're projected to reach critical levels (below 20) at <span className="interpolated-data">72</span>. People below this threshold are restricted in everyday activities like climbing stairs or walking briskly.`,
        components: [
          {
            type: MetricGraph,
            props: {
              metric: mockMetric,
              userData: mockUserData
            }
          }
        ],
        afterGraph: `### Why we use VO2 Max for your score, and how we calculated it

VO2 max is the measure for peak aerobic capacity: the maximum rate at which a person can utilize oxygen.

We use a methodology called the Cooper test, which calculates your VO2 Max based on the distance you can run in 12 minutes.

**Current VO2 Max measurement: <span className="interpolated-data">42 ml/kg/min</span>**

<table>
  <tr>
    <th>Category</th>
    <th>Value</th>
  </tr>
  <tr>
    <td>Workout used</td>
    <td></td>
  </tr>
  <tr>
    <td>Precision</td>
    <td></td>
  </tr>
  <tr>
    <td>Gear</td>
    <td></td>
  </tr>
  <tr>
    <td>Pop. Percentile</td>
    <td></td>
  </tr>
</table>


### Your VO2 Max Progression
-> here add the table with many measures (more precise measures needed)


### How to improve your V02Max precision
Your current v02Max precision is <span className="interpolated-data">±5%</span>.
// walk through doing a 12 mins cooper test right. 
      `
      }),
      training: (props) => ({
        content: `# Cardiovascular Training Guide // TODO

## Zone-Based Training
Train in different heart rate zones to target specific adaptations:
- Zone 2 (60-70%): Build aerobic base
- Zone 3-4 (70-90%): Improve performance
- Zone 5 (90%+): Increase VO2 max

## Weekly Structure
- 2-3 low intensity sessions
- 1-2 high intensity sessions
- Adequate recovery between sessions
      `,
        components: [
          {
            type: MetricGraph,
            props: {
              metric: mockMetric,
              userData: mockUserData
            }
          }
        ],
        afterGraph: `### Why we use VO2 Max, and how we calculated it

VO2 max is the measure of peak aerobic capacity, and corresponds to the maximum rate at which a person can utilize oxygen.

We use a methodology called the Cooper score, which calculates your VO2 Max based on the distance you can run in 12 minutes.

### Your VO2 Max Progression`
      }),
      theory: (props) => ({
        content: `## What is cardiorespiratory fitness, why is it important?
Cardiorespiratory fitness is how efficiently your body can deliver oxygen to your muscles, and how efficiently your muscles can extract that oxygen, enabling you to run (or walk) or cycle or swim long distances.

It also comes into play in daily life, manifesting as physical stamina. **The more aerobically fit you are, the more energy you will have for whatever you enjoy doing - even if your favorite activity is shopping.**

## The link between cardiorespiratory fitness and longevity
        
The strong association between cardiorespiratory fitness and longevity has long been known. 

<span className="study-link" onClick="window.open('https://jamanetwork.com/journals/jamanetworkopen/fullarticle/2707428', '_blank')">A large-scale 2018 research study ↗</span> tracked over 120,000 participants and revealed a clear link: the higher your cardiovascular fitness (VO2 max), the lower your overall risk of death.

The findings were striking - **if you're in the bottom 25% fitness level for your age, you're about 4 times more likely to die than someone in the top 25%, and 5 times more likely compared to someone with elite fitness levels (top 2.3%)**



 But here's the encouraging news - you don't need to become an elite athlete to see benefits. **Simply improving from the bottom 25% to even just below average (25-50th percentile) cuts your risk of death by nearly half**.
      
<span className="study-link" onClick="window.open('https://pubmed.ncbi.nlm.nih.gov/35926933/', '_blank')">A comprehensive 2022 study ↗</span> published in the Journal of the American College of Cardiology reinforced these findings. Looking at 750,000 US veterans between 30 and 95 years old, across all genders and races, they found remarkably similar results: **people in the lowest 20% fitness level had a 4.09 times higher risk of death compared to the top 2%.** Even those with moderate fitness (40-60th percentile) had double the mortality risk compared to the fittest group. As the authors of the study concluded: 
<span className="knowledge-quote">
"Cardiorespiratory fitness is inversely associated with long-term mortality with no observed upper limit of benefit."
</span>

## The science behind it
There are two fundamental reasons for this. 

The first reason is endurance: it builds a base of endurance for anything else you do in life, whether that is riding your bike in a one-hundred-mile century ride or playing with your kids or grandkids. 

The second reason relates to mitochondrial health: it plays a crucial role in preventing chronic disease by improving the health and efficiency of your mitochondria. When we are exercising in zone 2, most of the work is being done by our type 1, or "slow-twitch," muscle fibers, which are dense with mitochondria. These cellular "engines" can burn both glucose and fat, making them fundamental to our metabolic and brain health. This is why I place such emphasis on long, steady endurance training in zone 2.

      
      `
      }),
    }
  },
  strength: {
    info: {
      title: "Strength Foundations",
      description: "Building and maintaining functional strength."
    },
    subsections: {
      theory: `
      A ten-year observational study of roughly 4,500 subjects ages fifty and older found that those with low muscle mass were at 40 to 50 percent greater risk of mortality than controls, over the study period. Further analysis revealed that it's not the mere muscle mass that matters but the strength of those muscles, their ability to generate force. It's not enough to build up big pecs or biceps in the gym - those muscles also have to be strong. They have to be capable of creating force. Subjects with low muscle strength were at double the risk of death, while those with low muscle mass and/or low muscle strength, plus metabolic syndrome, had a 3 to 3.33 times greater risk of all-cause mortality.


      Strength may even trump cardiorespiratory fitness, at least one study sug-gests. Researchers following a group of approximately 1,500 men over forty with hypertension, for an average of about eighteen years, found that even it a man was in the bottom half of cardiorespiratory fitness, his risk of all-cause mortality was still almost 48 percent lower if he was in the top third of the group in terms of strength versus the bottom third.


Much of this effect, I think, likely has to do with improved mechanics: exercise strengthens the heart and helps maintain the circulatory system. As well see later in this chapter, it also improves the health of the mitochondria, the crucial little organelles that produce energy in our cells (among other things). That, in turn, improves our ability to metabolize both glucose and fat.



One Chilean study looked at about one thousand men and four hundred women, with an average age of seventy-four at enrollment. The researchers divided the subjects into quartiles, based on their appendicular lean mass index (technically, the muscle mass of their ex-tremities, arms and legs, normalized to height), and followed them over time.
After twelve years, approximately 50 percent of those in the lowest quartile were dead, compared to only 20 percent of those in the highest quartile for lean mass. While we can't establish causality here, the strength and reproducibility of findings like this suggest this is more than just a correlation. Muscle helps us survive old age.


# Strength Training Fundamentals

## What is Strength Training?
Strength training involves exercises that challenge your muscles to overcome resistance, promoting growth and adaptation.

## Benefits
- Increased muscle mass and bone density
- Enhanced metabolic rate
- Improved functional capacity

## Key Principles
Progressive overload and proper form are essential for safe, effective training.
      `,
      "your score": `
# Your Strength Metrics

## Relative Strength
How strong you are in relation to your body weight. Key for functional fitness.

## Movement Patterns
Your performance across fundamental movement patterns:
- Push/Pull capacity
- Squat strength
- Hip hinge power
      `,
      training: `
# Strength Training Guidelines

## Exercise Selection
Focus on compound movements that engage multiple muscle groups:
- Squats and deadlifts
- Push-ups and pull-ups
- Overhead pressing

## Programming
- 2-3 sessions per week
- Progressive overload
- Balance pushing and pulling
      `
    }
  },
  stability: {
    info: {
      title: "Stability & Mobility",
      description: "Foundation for movement quality and injury prevention."
    },
    subsections: {
      theory: `
# Stability Fundamentals

## What is Stability?
Stability is your body's ability to maintain control during movement and resist unwanted motion.

## Importance
Good stability reduces injury risk and improves movement efficiency.

## Core Concepts
- Joint centration
- Motor control
- Balance systems
      `,
      "your score": `
# Your Stability Metrics

## Balance Score
Your ability to maintain position during static and dynamic challenges.

## Movement Quality
Assessment of your fundamental movement patterns:
- Single leg stability
- Core control
- Joint mobility
      `,
      training: `
# Stability Training Guide

## Key Exercises
- Single leg movements
- Core anti-rotation
- Balance challenges

## Implementation
- Daily movement practice
- Pre-workout activation
- Recovery mobility work
      `
    }
  }
};

export default knowledgeContent;

// ## VO2 Max: ${value}/100
// Your VO2 max indicates how efficiently your body uses oxygen during exercise. A higher score means better cardio fitness.

// ## Heart Rate Variability: ${value}ms
// HRV is a key indicator of recovery and stress levels. Higher variability typically indicates better cardiovascular health.

// ## Resting Heart Rate: ${value}bpm
// Your resting heart rate is a baseline measure of cardiovascular fitness. Lower is generally better.


// Your score

// * Heart Rate: <span style="color: #ff4d4d; font-size: 1.1em; font-weight: bold">${props.currentHr} BPM</span>
// * Training Zone: <span style="background-color: #e6ffe6; padding: 2px 6px; border-radius: 3px">${props.trainingZone}</span>
// * Weekly Distance: <span style="color: #4d79ff; text-decoration: underline">${props.weeklyDistance} km</span>
// * Recovery Status: <span style="background-color: #90EE90; padding: 2px 6px; border-radius: 3px">${props.recoveryStatus}</span>

// ## Last Workout Summary

// * Activity: <span style="font-weight: bold">${props.lastWorkout}</span>
// * Calories: <span style="color: #ff8533">${props.caloriesBurned} kcal</span>
// * Time in Zone: <span style="background-color: #f0f0f0; padding: 2px 6px; border-radius: 3px">${props.timeInZone}</span>

// ---

// ## Understanding Cardiovascular Fitness

// Cardiovascular fitness is your body's ability to deliver oxygen to working muscles during sustained physical activity.

// Your VO2 Max of <span style="color: blue">${props.vo2max}</span> and HRV of <span style="color: green">${props.hrv}</span> indicate your current fitness level.

// A resting heart rate of ${props.restingHr} bpm suggests good baseline cardiovascular health.

// # What metric is it based on? 
// We measure your peak aerobic capacity (VO2 Max) and your recovery capacity (HRV). 

// Peak aerobic cardiorespiratory fitness) measured in terms of VO, max, is perhaps the single most powerful marker for longevity. VO2 max represents the maximum rate at which a person can utilize oxygen. This is measured, naturally, while a person is exercising at essentially their upper limit of effort. (If you've ever had this test done, you will know just how unpleasant it is.) The more oxygen your body is able to use, the higher your VOz max.
// ## How is it calculated? 
// We apply a decrease rate your current level of v02 max. 
// ## VO2 Max: \${vo2max}/100
// Your VO2 max indicates how efficiently your body uses oxygen during exercise. A higher score means better cardio fitness.

// ## Heart Rate Variability: \${hrv}ms
// HRV is a key indicator of recovery and stress levels. Higher variability typically indicates better cardiovascular health.

// ## Resting Heart Rate: \${restingHr}bpm
// Your resting heart rate is a baseline measure of cardiovascular fitness. Lower is generally better.