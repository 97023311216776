import React from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import "./MetricGraph.scss";
import { getPercentileColor } from "../utils/profile_helper";

const metricData = {
  stability_score: [
    { age: 20, 0.25: 70, "0.50": 80, 0.75: 90, "1.0": 100 },
    { age: 30, 0.25: 68, "0.50": 78, 0.75: 88, "1.0": 98 },
    { age: 40, 0.25: 65, "0.50": 75, 0.75: 85, "1.0": 95 },
    { age: 50, 0.25: 62, "0.50": 72, 0.75: 82, "1.0": 92 },
    { age: 60, 0.25: 60, "0.50": 70, 0.75: 80, "1.0": 90 },
    { age: 70, 0.25: 58, "0.50": 68, 0.75: 78, "1.0": 88 },
    { age: 80, 0.25: 55, "0.50": 65, 0.75: 75, "1.0": 85 },
    { age: 90, 0.25: 52, "0.50": 62, 0.75: 72, "1.0": 82 },
  ],
  ftp: [
    { age: 20, 0.25: 220, "0.50": 260, 0.75: 300, "1.0": 340 },
    { age: 30, 0.25: 230, "0.50": 270, 0.75: 310, "1.0": 350 },
    { age: 40, 0.25: 220, "0.50": 260, 0.75: 300, "1.0": 340 },
    { age: 50, 0.25: 200, "0.50": 240, 0.75: 280, "1.0": 320 },
    { age: 60, 0.25: 180, "0.50": 220, 0.75: 260, "1.0": 300 },
    { age: 70, 0.25: 160, "0.50": 200, 0.75: 240, "1.0": 280 },
    { age: 80, 0.25: 140, "0.50": 180, 0.75: 220, "1.0": 260 },
    { age: 90, 0.25: 120, "0.50": 160, 0.75: 200, "1.0": 240 },
  ],
  v02_max: [
    { age: 20, 0.25: 45, "0.50": 50, 0.75: 55, "1.0": 60 },
    { age: 30, 0.25: 43, "0.50": 48, 0.75: 53, "1.0": 58 },
    { age: 40, 0.25: 40, "0.50": 45, 0.75: 50, "1.0": 55 },
    { age: 50, 0.25: 37, "0.50": 42, 0.75: 47, "1.0": 52 },
    { age: 60, 0.25: 35, "0.50": 40, 0.75: 45, "1.0": 50 },
    { age: 70, 0.25: 32, "0.50": 37, 0.75: 42, "1.0": 47 },
    { age: 80, 0.25: 29, "0.50": 34, 0.75: 39, "1.0": 44 },
    { age: 90, 0.25: 26, "0.50": 31, 0.75: 36, "1.0": 41 },
  ],
};

const generateUserDataPoints = (metricData, userPercentile) => {
  return metricData.map((dataPoint) => {
    const age = dataPoint.age;
    let value;

    if (userPercentile <= 0.25) {
      value = dataPoint["0.25"];
    } else if (userPercentile <= 0.5) {
      value =
        dataPoint["0.25"] +
        (dataPoint["0.50"] - dataPoint["0.25"]) *
          ((userPercentile - 0.25) / 0.25);
    } else if (userPercentile <= 0.75) {
      value =
        dataPoint["0.50"] +
        (dataPoint["0.75"] - dataPoint["0.50"]) *
          ((userPercentile - 0.5) / 0.25);
    } else {
      value =
        dataPoint["0.75"] +
        (dataPoint["1.0"] - dataPoint["0.75"]) *
          ((userPercentile - 0.75) / 0.25);
    }

    return { age, value: Math.round(value * 100) / 100 };
  });
};

const MetricGraph = ({ metric, userData }) => {
  const metricName = metric.real_name;
  const metricCode = metric.metric_code.toLowerCase();
  const userMetric = userData.metrics[metricCode];
  const userMetricPercentile = metric.percentile;

  const metricColor = getPercentileColor(userMetricPercentile);

  const data = metricData[metricCode] || [];
  const userDataPoints = generateUserDataPoints(data, userMetricPercentile);

  const minValue = Math.min(...data.map((d) => d["0.25"])) - 5;
  const maxValue = Math.max(...data.map((d) => d["1.0"])) + 5;

  const renderLegend = (props) => {
    const { payload } = props;
    const customPayload = [
      payload.find((entry) => entry.value === "Your data"),
      payload.find(
        (entry) => entry.value === "Median - 25th / 75% perc. dotted"
      ),
    ].filter(Boolean);

    return (
      <ul
        style={{ fontSize: "10px", listStyle: "none", padding: 0, margin: 0 }}
      >
        {customPayload.map((entry, index) => (
          <li
            key={`item-${index}`}
            style={{ display: "inline-block", marginRight: "10px" }}
          >
            <span
              style={{
                display: "inline-block",
                width: "20px",
                height: "2px",
                backgroundColor: entry.color,
                marginRight: "5px",
                verticalAlign: "middle",
                borderBottom: entry.strokeDasharray
                  ? "none"
                  : `2px solid ${entry.color}`,
                borderTop: entry.strokeDasharray
                  ? `2px dotted ${entry.color}`
                  : "none",
              }}
            ></span>
            {entry.value}
          </li>
        ))}
      </ul>
    );
  };

  return (
    <div className="metric-graph">
      {/* <h3>{metricName}</h3> */}
      <ResponsiveContainer width="100%" height={200}>
        <LineChart data={data}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="age" />
          <YAxis domain={[minValue, maxValue]} />
          <Tooltip />
          <Line
            type="monotone"
            dataKey="0.50"
            stroke="#656565"
            strokeWidth={2}
            dot={false}
            name="Median - 25th / 75% perc. dotted"
          />
          <Line
            type="monotone"
            dataKey="0.25"
            stroke="#656565"
            strokeDasharray="3 3"
            dot={false}
            name="25th percentile"
          />
          <Line
            type="monotone"
            dataKey="0.75"
            stroke="#656565"
            strokeDasharray="3 3"
            dot={false}
            name="75th percentile"
          />

          {/* User metrics line */}
          <Line
            type="monotone"
            data={userDataPoints}
            dataKey="value"
            stroke={metricColor}
            strokeWidth={2}
            name="Your data"
            dot={false}
          />
          <Legend content={renderLegend} verticalAlign="bottom" height={36} />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};

export default MetricGraph;
