import React, { useState, useEffect } from "react";
import "./ProfileNew.scss";
import stravaLogo from "../images/pwd_strava.svg";
import infoIcon from "../images/info_2.svg";
import WidgetGraph from './WidgetGraph';
import Knowledge from './Knowledge';
import { createDimDataForProfilev3 } from "../utils/metrics_processing_v3";
import { calculatePercentileTag } from "../utils/profile_helper";
import Metric from './v3_components/Metric';
import { WidgetHome } from './v3_components/Widget';
import cardioIcon from '../images/category_icons/cardio.svg';
import strengthIcon from '../images/category_icons/strength.svg';
import stabilityIcon from '../images/category_icons/stability.svg';

import Dimension from './v3_components/Dimension';
import { WikiFreeForm } from './v3_components/DimensionHelpers';

const FilledIndicator = ({ type, percentage }) => {
  // console.log(percentage, type)
  const fillHeight = percentage === 100 ? '0%' : `${100 - percentage + 7}%`;

  
  return (
    <div 
      className="filled-indicator"
      style={{ 
        "--fill-height": fillHeight,
      }}  
    >
      <div className="background-image">
        <img 
          src={require(`../images/${type}_filled.svg`)} 
          alt={`${type} indicator background`} 
        />
      </div>
      <div className="fill">
        <img 
          src={require(`../images/${type}_filled.svg`)} 
          alt={`${type} indicator fill`} 
        />
      </div>
    </div>
  );
};


const ProfileNew = ({ userData, isCurrentUser }) => {
  const [knowledgeState, setKnowledgeState] = useState({
    showKnowledge: false,
    section: 'cardio',
    subsection: 'cardio score'
  });

  const dimData = userData ? createDimDataForProfilev3(userData) : null;

  // console.log(dimData)
  // console.log(JSON.stringify(userData, null, 2))

  console.log('cardio effort', dimData?.cardio.effort)

  const [showDimension, setShowDimension] = useState(null);

  if (!userData || !userData.user_info) return null;

  const { firstname, lastname, profile } = userData.user_info.core;

  if (knowledgeState.showKnowledge) {
    return (
      <Knowledge
        section={knowledgeState.section}
        subsection={knowledgeState.subsection}
        setKnowledgeState={setKnowledgeState}
      />
    );
  }

  if (showDimension) {
    return (
      <Dimension 
        dimension={showDimension} 
        dimData={dimData}
        onClose={() => setShowDimension(null)} 
      />
    );
  }

  return (
    <div className="profile-new">
      <div className="container">
        {/* Header */}
        <div className="profile-header">
          <div className="header-content">
            <div className="profile-info">
              {window.location.href.includes("profile") ? (
                <img src={require("../images/usain.png")} alt="Profile" className="profile-image" />
              ) : (
                <img src={profile} alt="Profile" className="profile-image" />
              )}
              <span className="profile-name">{firstname}</span>
            </div>

            {dimData?.life_score && (
              <WikiFreeForm reference="longevity_score">
                <div className="life-score-section">
                  <FilledIndicator 
                    type="life" 
                    percentage={dimData.life_score.score * 100}
                    color={dimData.life_score.color}
                  />
                  <Metric 
                    title="Longevity score" 
                    value={`${Math.round(dimData.life_score.score * 100)}%`} 
                    variant="life-score"
                    showInfoIcon={true}
                    onInfoClick={(e) => {
                      e.stopPropagation(); // Prevent WikiFreeForm from triggering
                      setShowDimension('longevity');
                    }}
                  />
                </div>
              </WikiFreeForm>
            )}
          </div>
        </div>

        {/* Single Metrics Section */}
        <div className="dimension-section">
          <div className="dimension-title">
            <span>Metrics</span>
          </div>
          <div className="dimension-widgets">
            <div onClick={() => dimData?.cardio?.measure?.percentile !== undefined && setShowDimension('cardio')}>
              <WidgetHome
                title="Cardio"
                variant="cardio"
                icon={cardioIcon}
                percentile={dimData?.cardio?.measure?.percentile}
                effortBars={dimData?.cardio?.effort?.recommended?.nb_bars || 0}
                effortTitle="Cardio effort"
              />
            </div>
            <div onClick={() => dimData?.strength?.measure?.percentile !== undefined && setShowDimension('strength')}>
              <WidgetHome
                title="Strength"
                variant="strength"
                icon={strengthIcon}
                percentile={dimData?.strength?.measure?.percentile}
                effortBars={dimData?.strength?.effort?.cycling_as_strength_proxy?.recommended?.nb_bars || 0}
                effortTitle="Leg strength effort"
              />
            </div>
            <div onClick={() => dimData?.stability?.measure?.percentile !== undefined && setShowDimension('stability')}>
              <WidgetHome
                title="Stability"
                variant="stability"
                icon={stabilityIcon}
                percentile={dimData?.stability?.measure?.percentile}
                effortBars={(dimData?.stability?.measure?.value * 5) / 3 || 0}
                effortTitle="Movement Consistency"
              />
            </div>
          </div>
        </div>

        {/* Add this at the bottom of the container */}
        <img src={stravaLogo} alt="Powered by Strava" className="strava-logo-bottom" />
      </div>
    </div>
  );
};
export default ProfileNew;
