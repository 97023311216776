import React, { useState, useRef, useEffect } from "react";
import "./Profile.scss";

import {
  keyDataFromMetrics,
  getMetricInsight,
  getLifeScoreEmoji,
} from "../utils/profile_helper";
import { profileMetrics } from "../utils/profile_data";
import stravaLogo from "../images/pwd_strava.svg";
import shareIcon from "../images/shareIcon.png";
import decreasingIcon from "../images/decreasing_icon.png";
import increasingIcon from "../images/increasing_icon.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faRotate } from "@fortawesome/free-solid-svg-icons";
import ExpandedWidget from "./ExpandedWidget";
import { getLastSyncTime } from "../utils/metrics_helper";

const Profile = ({ userData }) => {
  const [expandedWidget, setExpandedWidget] = useState(null);
  const expandedContentRef = useRef(null);
  const widgetRefs = useRef({});

  const processedMetrics = keyDataFromMetrics(userData);
  // console.log(processedMetrics);

  const handleWidgetClick = (metricCode) => {
    setExpandedWidget((prevExpanded) =>
      prevExpanded === metricCode ? null : metricCode
    );
  };

  useEffect(() => {
    if (expandedWidget) {
      setTimeout(() => {
        widgetRefs.current[expandedWidget]?.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
      }, 100);

      if (expandedContentRef.current) {
        let opacity = 0;
        const intervalId = setInterval(() => {
          opacity += 0.1;
          if (expandedContentRef.current) {
            expandedContentRef.current.style.opacity = opacity;
          }
          if (opacity >= 1) clearInterval(intervalId);
        }, 30);
      }
    }
  }, [expandedWidget]);

  if (!userData || !userData.metrics || !userData.user_info) {
    return null;
  }

  const { user_info, metrics } = userData;
  const { firstname, lastname, profile } = user_info.core;

  const renderMetricWidget = (metricCode, isMainWidget) => {
    const metric = processedMetrics[metricCode];
    const isExpanded = expandedWidget === metricCode;
    const isLimiting = metric.tag_codes.includes("limiting");
    const hasMissingMetric = metric.value === null;

    // Get the first tag code and its corresponding insight
    const firstTagCode =
      metric.tag_codes && metric.tag_codes.length > 0
        ? metric.tag_codes[0]
        : null;

    let insight = null;
    try {
      insight = firstTagCode ? getMetricInsight(firstTagCode) : null;
    } catch (error) {
      console.error(`Error getting insight for ${firstTagCode}:`, error);
    }

    // Check if insight is not null before accessing its properties
    const hasValidInsight = insight && insight.icon && insight.text;

    const showIncreasingIcon =
      !isMainWidget && metric.tag_codes.includes("increasing");
    const showDecreasingIcon =
      !isMainWidget && metric.tag_codes.includes("decreasing");
// ss
    return (
      <>
        <div
          key={metricCode}
          ref={(el) => (widgetRefs.current[metricCode] = el)}
          className={`widget ${isMainWidget ? "main-widget" : ""} ${
            hasMissingMetric ? "missing-metric" : ""
          } ${isExpanded ? "expanded" : ""}`}
          onClick={() => !hasMissingMetric && handleWidgetClick(metricCode)}
          style={
            isMainWidget
              ? {
                  boxShadow: `0 4px 3px ${metric.color}33`,
                  backgroundColor: `${metric.color}08`, // Changed from 0D to 08 (0.03 opacity)
                  border: `1px solid ${metric.color}`,
                }
              : {}
          }
        >
          {/* {isLimiting && <div className="limiting-band">Limiting metric!</div>} */}
          <div className="widget-content">
            <div className="header">
              <div className="emoji">
                {isMainWidget
                  ? getLifeScoreEmoji(metric.percentile)
                  : metric.emoji}
              </div>
              <div className="title-container">
                <div className="title">{metric.simple_name}</div>
                {!isMainWidget && (
                  <div className="metricDescription">{metric.real_name}</div>
                )}
              </div>
            </div>
            <div className="metric-container">
              {metric.value !== null ? (
                <div className="metric-row">
                  <div className={isMainWidget ? "metricValue" : "metricSmall"}>
                    {metric.value.toFixed(metric.decimalPlaces)}
                    {metric.show_unit && (
                      <span className="units"> {metric.unit}</span>
                    )}
                    {showIncreasingIcon && (
                      <img
                        src={increasingIcon}
                        alt="Increasing"
                        className="trend-icon"
                      />
                    )}
                    {showDecreasingIcon && (
                      <img
                        src={decreasingIcon}
                        alt="Decreasing"
                        className="trend-icon"
                      />
                    )}
                  </div>
                  <div className="percentile-insight-container">
                    {metric.percentileTag && (
                      <div
                        className={
                          isMainWidget ? "percentile" : "percentileSmall"
                        }
                        style={{
                          backgroundColor: metric.color,
                        }}
                      >
                        {metric.percentileTag}
                      </div>
                    )}
                    {hasValidInsight && (
                      <div className={`metric-insight ${insight.className}`}>
                        {insight.icon}
                        <span className="text">{insight.text}</span>
                      </div>
                    )}
                  </div>
                </div>
              ) : (
                <div className="missing-data-message">
                  {metricCode === "ftp"
                    ? "No eligible rides activities found."
                    : "Missing metric."}
                </div>
              )}
            </div>
          </div>
          {!hasMissingMetric && (
            <div
              className={`expanded-content ${isExpanded ? "visible" : ""}`}
              ref={isExpanded ? expandedContentRef : null}
            >
              <ExpandedWidget metric={metric} userData={userData} />
            </div>
          )}
          {!hasMissingMetric && (
            <FontAwesomeIcon icon={faChevronDown} className="chevron-icon" />
          )}
        </div>
      </>
    );
  };

  const renderMainWidget = (metricCode) => renderMetricWidget(metricCode, true);
  const renderDetailedWidget = (metricCode) =>
    renderMetricWidget(metricCode, false);

  const mainMetrics = profileMetrics
    .filter((m) => m.isMainWidget)
    .map((m) => m.metric_code);
  const detailedMetrics = profileMetrics
    .filter((m) => !m.isMainWidget)
    .map((m) => m.metric_code);

  const handleShare = () => {
    const shareData = {
      title: "Check out my profile on More Life",
      text: "See my longevity metrics and more!",
      url: "https://getmorelife.io/profile",
    };

    if (navigator.share) {
      navigator
        .share(shareData)
        .catch((error) => console.error("Error sharing", error));
    } else {
      window.open(
        `mailto:?subject=${shareData.title}&body=${shareData.text} ${shareData.url}`
      );
    }
  };

  const demoMode =
    window.location.href.includes("profile") ||
    window.location.href.includes("eseror");

  // Assuming you have a metric that represents the Strava sync
  // You might need to adjust this based on your actual data structure
  const anyMetric = Object.values(userData.metrics)[0]; // Get the first metric

  const lastSyncMessage = getLastSyncTime(anyMetric);

  return (
    <div className="profile-old">


    <div className="container ">
      <div className="profileHeader">
        <div className="profileInfo">
          <div className="profile-image-and-name">
            {window.location.href.includes("profile") ? (
              <img
                src={require("../images/usain.png")}
                alt="Profile"
                className="profile-image"
              />
            ) : (
              <img src={profile} alt="Profile" className="profile-image" />
            )}
            <h1 className="profileName">
              {firstname} {lastname}
            </h1>
          </div>
          <img
            src={shareIcon}
            alt="Share"
            className="share-icon"
            onClick={handleShare}
          />
        </div>
      </div>

      <div className="widgets">{mainMetrics.map(renderMainWidget)}</div>

      <div className="category-title">
        <span>Connected metrics</span>
        <div className="sync-info">
          <FontAwesomeIcon icon={faRotate} className="sync-icon" />
          <span className="category-subtitle">{lastSyncMessage}</span>
        </div>
      </div>

      <div className="widgets">{detailedMetrics.map(renderDetailedWidget)}</div>

      {/* Add the hardcoded advice widget */}

      {demoMode && false && (
        <>
          <div className="category-title">Goals</div>
          <div className="empty-state clickable">
            <span className="plus-icon">+</span>
            <span className="empty-state-message">Set Your Next Goal</span>
          </div>
        </>
      )}

      {/* {demoMode && (
        <>
          <div className="category-title">
            How to improve
            <span className="category-subtitle">Only visible to you</span>
          </div>

          <div className="widget advice-widget">
            <div className="header">
              <div className="emoji">💡</div>
              <div className="title-container">
                <div className="title">Advice</div>
                <div className="subtitle">(only visible to you)</div>
              </div>
            </div>
            <p className="advice-content">
              Focus on balance and mobility exercises using yoga and functional
              movement training. <br /> <br />
              <a>Achieve 30-second single-leg balance</a> <br />
              <a>Master full-range squat with proper form</a>
            </p>
          </div>
        </>
      )} */}

      <img src={stravaLogo} alt="Powered by Strava" className="stravaLogo" />
    </div>
    </div>

  );
};

export default Profile;
