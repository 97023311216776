import React from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import './PercentileTag.scss';

// Export both the function and the component
export const getPercentileText = (percentile) => {
    if (percentile >= 0.99) return "Top 1%";
    if (percentile >= 0.98) return "Top 2%";
    if (percentile >= 0.97) return "Top 3%";
    if (percentile >= 0.96) return "Top 4%";
    if (percentile >= 0.95) return "Top 5%";
    if (percentile >= 0.94) return "Top 6%";
    if (percentile >= 0.93) return "Top 7%";
    if (percentile >= 0.92) return "Top 8%";
    if (percentile >= 0.91) return "Top 9%";
    if (percentile >= 0.9) return "Top 10%";
    if (percentile >= 0.85) return "Top 15%";
    if (percentile >= 0.8) return "Top 20%";
    if (percentile >= 0.75) return "Top 25%";
    if (percentile >= 0.5) return "Above av.";
    if (percentile >= 0.25) return "Below av.";
    return "Bottom 25%";
  };


export const getPercentileTextPrecise = (percentile) => {
    if (percentile >= 0.99) return "top 1%";
    if (percentile >= 0.98) return "top 2%";
    if (percentile >= 0.97) return "top 3%";
    if (percentile >= 0.96) return "top 4%";
    if (percentile >= 0.95) return "top 5%";
    if (percentile >= 0.94) return "top 6%";
    if (percentile >= 0.93) return "top 7%";
    if (percentile >= 0.92) return "top 8%";
    if (percentile >= 0.91) return "top 9%";
    if (percentile >= 0.9) return "top 10%";
    if (percentile >= 0.85) return "top 15%";
    if (percentile >= 0.8) return "top 20%";
    if (percentile >= 0.75) return "top 25%";
    if (percentile >= 0.5) return "top 50%";
    if (percentile >= 0.25) return "bottom 50%";
    return "bottom 25%";
};

const unavailableMessages = {
  cardio: 'Insufficient cardio data',
  strength: 'Insufficient strength data',
  stability: 'Insufficient stability data',
  default: 'N/A'
};

const PercentileTag = ({ percentile, variant }) => {
  if (percentile === undefined || percentile === null) {
    const message = unavailableMessages[variant] || unavailableMessages.default;
    return (
      <div className="percentile-tag unavailable">
        <span className="percentile-text">{message}</span>
      </div>
    );
  }

  const getStarCount = (percentile) => {
    if (percentile >= 0.95) return 1;
    return 0;
  };

  const stars = getStarCount(percentile);
  const text = getPercentileText(percentile);

  return (
    <div className={`percentile-tag ${variant || ''}`}>
      {stars > 0 && [...Array(stars)].map((_, index) => (
        <FontAwesomeIcon key={index} icon={faStar} className="star-icon" />
      ))}
      <span className="percentile-text">{text}</span>
    </div>
  );
};

// Export the component as default
export default PercentileTag; 