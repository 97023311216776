import React, { useState, useEffect } from "react";
import { useSwipeable } from "react-swipeable";
import Button from "./reusable/Button";
import Dots from "./Dots";
import "./Onboarding.scss";
import stravaButton from "../images/btn_strava_connectwith_orange.svg";
import onboardingUnique from "../images/onboarding/onboarding_unique.png";
import YearPicker from "./reusable/YearPicker";
import { BASE_URL } from "../config";

const Onboarding = () => {
  const [currentScreen, setCurrentScreen] = useState(0);
  const [selectedYear, setSelectedYear] = useState(1985);
  const [isTestMode, setIsTestMode] = useState(false);

  useEffect(() => {
    setIsTestMode(window.location.hostname.includes("localhost"));
  }, []);

  const screens = [
    {
      title: "Build your longevity profile",
      subtitle:
        "We look at the details of your Strava workouts to measure key longevity metrics. See your metrics and receive tips to train towards a longer, healthier life.",
      image: onboardingUnique,
      buttonText: "Create profile",
    },
    {
      title: "Provide birth year and connect with Strava",
      subtitle:
        "We use your birth year to provide more accurate longevity insights.",
      component: (
        <YearPicker
          onChange={(year) => setSelectedYear(year)}
          defaultYear={1985}
          startYear={1900}
          endYear={new Date().getFullYear() - 18}
        />
      ),
      buttons: (
        <div className="button-container strava-button">
          <img
            src={stravaButton}
            alt="Connect with Strava"
            height="48px"
            onClick={() => {
              const testModeParam = isTestMode ? "true" : "false";
              window.location.href = `${BASE_URL}/authorize?birth_year=${selectedYear}&email=&testMode=${testModeParam}`;
            }}
          />
        </div>
      ),
    },
  ];

  const { title, subtitle, image, buttons, buttonText, component } =
    screens[currentScreen];

  const handlers = useSwipeable({
    onSwipedLeft: () => {
      if (currentScreen < screens.length - 1) {
        setCurrentScreen(currentScreen + 1);
      }
    },
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
  });

  return (
    <div className="onboarding" {...handlers}>
      <div className="header">
        <h1 className="onboarding-title">{title}</h1>
        {subtitle && <h2 className="onboarding-subtitle">{subtitle}</h2>}
      </div>
      <div className="content">
        {component
          ? component
          : image && <img src={image} alt={title} className="info-image" />}
      </div>
      <div className="footer">
        {/* <Dots total={screens.length} current={currentScreen} /> */}
        {buttons
          ? buttons
          : buttonText && (
              <div className="button-container">
                <Button
                  color="black"
                  cta={buttonText}
                  onClick={() => setCurrentScreen(currentScreen + 1)}
                />
              </div>
            )}
      </div>
    </div>
  );
};

export default Onboarding;
