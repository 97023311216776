import React, { useState } from 'react';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import knowledgeContent from '../content/knowledgeContent';
import './Knowledge.scss';

const Knowledge = ({ section, subsection, setKnowledgeState }) => {
  const [activeSubsection, setActiveSubsection] = useState(
    subsection || (section === 'general' ? 'app' : 'theory')
  );

  const testValues = {
    vo2max: 42,
    hrv: 45,
    restingHr: 65,
    currentHr: 132,
    trainingZone: "Zone 2",
    weeklyDistance: 25.5,
    recoveryStatus: "Good",
    lastWorkout: "5km Run",
    caloriesBurned: 450,
    timeInZone: "45 min"
  };

  const getSubsectionContent = (section, subsection) => {
    const template = knowledgeContent[section]?.subsections?.[subsection];
    if (!template) return { content: '' };
    
    if (typeof template === 'function') {
      const result = template({
        ...testValues,
        setKnowledgeState
      });
      
      return typeof result === 'string' 
        ? { content: result }
        : result;
    }
    
    return {
      content: template.replace(/\${(\w+)}/g, (match, prop) => {
        return testValues[prop] || match;
      })
    };
  };

  console.log('Current section content:', knowledgeContent[section]);
  // console.log('Current subsection content:', knowledgeContent[section]?.[activeSubsection]);

  const {title: sectionTitle, description: sectionDescription} = knowledgeContent[section]?.info || {};

  const getSubsections = () => {
    return Object.keys(knowledgeContent[section]?.subsections || {});
  };

  return (
    <div className="knowledge-container">
      <button 
        className="close-button"
        onClick={() => setKnowledgeState(prev => ({ ...prev, showKnowledge: false }))}
      >
        ×
      </button>

      <div className="knowledge-header">
        <div className="title-wrapper">
          <div className="category-icon">
            <img src={require(`../images/${section}_cat_title.svg`)} alt={`${section} icon`} />
          </div>
          <h1>{sectionTitle}</h1>
        </div>
        <p className="section-description">{sectionDescription}</p>

      </div>

      <div className="knowledge-menu">
        {getSubsections().map(subsection => (
          <button
            key={subsection}
            className={`menu-item ${activeSubsection === subsection ? 'active' : ''}`}
            onClick={() => setActiveSubsection(subsection)}
          >
            {subsection.toUpperCase()}
          </button>
        ))}
      </div>

      <div className="knowledge-content">
        {knowledgeContent[section]?.subsections?.[activeSubsection] && (
          <>
            <ReactMarkdown 
              rehypePlugins={[rehypeRaw]} 
              components={{}}
            >
              {getSubsectionContent(section, activeSubsection).content}
            </ReactMarkdown>
            
            {getSubsectionContent(section, activeSubsection).components?.map((component, index) => {
              const Component = component.type;
              return <Component key={index} {...component.props} />;
            })}

            {getSubsectionContent(section, activeSubsection).afterGraph && (
              <ReactMarkdown 
                rehypePlugins={[rehypeRaw]} 
                components={{}}
              >
                {getSubsectionContent(section, activeSubsection).afterGraph}
              </ReactMarkdown>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default Knowledge;
