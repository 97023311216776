import React from 'react';
import "./Metric.scss";
import infoIcon from '../../images/info_tertiary.svg';

const InfoIcon = ({ onClick }) => (
    <img
        src={infoIcon}
        alt="Info"
        className="info-icon"
        // onClick={onClick}
    />
);

const Metric = ({ title, value, variant, showInfoIcon, onInfoClick, unit }) => (
    <div className={`metric ${variant || ''}`}>
        <div className="metric-title-container">
            <span className="metric-title">{title}</span>
            {showInfoIcon && <InfoIcon  />}
        </div>

        <div className="metric-value">
            {value}
            {unit && <span className="unit">{unit}</span>}
        </div>
    </div>
);

export default Metric;