import './Indicator.scss';

export const EffortIndicator = ({ title, filledBars, variant }) => {
  const numFilledBars = Math.min(Math.round(filledBars), 5);
  
  return (
    <div className={`effort-indicator-widget`}>
      <div className={`effort-indicator ${variant || ''}`}>
        {[...Array(5)].map((_, index) => (
          <div 
            key={index} 
            className={`effort-bar ${index < numFilledBars ? 'filled' : ''}`}
          />
        ))}
      </div>

      <div className="metric">
        <div className="metric-title">{title}</div>
        <div className="metric-subtitle">
          {title === "Movement Consistency" ? "All time" : "Past 7 days"}
        </div>
      </div>
    </div>
  );
};

export const ConfidenceIndicator = ({ precision }) => {
  let status;
  let className;
  console.log(precision)
  
  if (precision >= 90) {
    status = 'High';
    className = 'high';
  } else if (precision < 50) {
    status = 'Low';
    className = 'low';
  } else {
    status = 'Medium';
    className = 'medium';
  }
  
  return (
    <div className={`confidence-indicator ${className}`}>
      {status}
      
      {/* <span>{precision}</span> */}
    </div>
  );
};

// export default EffortIndicator;
