// DEPRECATED AFTER v3 ? 



import { profileMetrics } from "./profile_data";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTriangleExclamation,
  faBullseye,
  faWaveSquare,
} from "@fortawesome/free-solid-svg-icons";
import {
  extractDatesAndValues,
  calculatePercentageChange,
  getLatestBatchFromMetric,
} from "./metrics_helper";

export function keyDataFromMetrics(userData) {
  const processedMetrics = {};

  const latestLifeScoreBatch = getLatestBatchFromMetric(
    userData.metrics.life_score
  );

  let bottleneckMetric = null;
  if (
    latestLifeScoreBatch &&
    userData.metrics.life_score[latestLifeScoreBatch] &&
    userData.metrics.life_score[latestLifeScoreBatch]["measures"] &&
    userData.metrics.life_score[latestLifeScoreBatch]["measures"][
      "main_measure"
    ] &&
    userData.metrics.life_score[latestLifeScoreBatch]["measures"][
      "main_measure"
    ]["optional_details"]
  ) {
    bottleneckMetric =
      userData.metrics.life_score[latestLifeScoreBatch]["measures"][
        "main_measure"
      ]["optional_details"]["bottleneck_metric"];
  }

  profileMetrics.forEach((metricInfo) => {
    const { metric_code } = metricInfo;
    const userMetric = userData.metrics[metric_code.toLowerCase()];

    if (!userMetric) {
      // console.log(`No datqa found for ${metric_code}, creating empty metric`);
      processedMetrics[metric_code] = createEmptyMetric(metricInfo);
      return;
    }

    const latestBatch = getLatestBatchFromMetric(userMetric);
    const batchData = userMetric[latestBatch];

    if (!batchData || !batchData.measures || !batchData.measures.main_measure) {
      processedMetrics[metric_code] = createEmptyMetric(metricInfo);
      return;
    }

    const mainMeasureData = batchData.measures.main_measure;
    // console.log("mainMeasureData", mainMeasureData);
    const value = mainMeasureData.value;
    const limiting = mainMeasureData.limiting;

    const percentile = mainMeasureData.percentile;
    const percentileTag = calculatePercentileTag(percentile);
    const color = getPercentileColor(percentile);
    const percentageChange = calculatePercentageChange(userMetric);

    // console.log("user data", userData);

    processedMetrics[metric_code] = {
      ...metricInfo,
      value,
      percentileTag,
      percentile,
      tag_codes: generate_tag_codes(
        metric_code,
        percentageChange.percentageChange,
        bottleneckMetric,
        mainMeasureData.precision // Pass precision value
      ),
      color,
      limiting,
      rawData: userMetric,
      percentageChange,
    };
  });

  console.log("processedMetrics", JSON.stringify(processedMetrics, null, 2));

  return processedMetrics;
}

export function getPercentileColor(percentileValue) {
  const clampedPercentile = Math.max(0, Math.min(1, percentileValue || 0));
  // console.log(`getPercentileColor - Percentile: ${clampedPercentile}`);

  if (clampedPercentile <= 0.25) {
    // console.log("getPercentileColor - Color: #FF4500 (Red)");
    return "#FF4500"; // Red for bottom 25%
  } else if (clampedPercentile <= 0.5) {
    // console.log("getPercentileColor - Color: #FF8C00 (Orange)");
    return "#FF8C00"; // Orange for 50-75%
  } else if (clampedPercentile <= 0.75) {
    // console.log("getPercentileColor - Color: #dfbc00 (Gold/Yellow)");
    return "#dfbc00"; // Gold/Yellow for 25-50%
  } else {
    // console.log("getPercentileColor - Color: #3DC88E (Green)");
    return "#3DC88E"; // Green for top 25%
  }
}

export function getLifeScoreEmoji(percentileValue) {
  const clampedPercentile = Math.max(0, Math.min(1, percentileValue || 0));
  // console.log(`getLifeScoreEmoji - Percentile: ${clampedPercentile}`);

  if (clampedPercentile <= 0.25) {
    // console.log("getLifeScoreEmoji - Emoji: ❤️ (Red heart)");
    return "🤍"; // Red heart for bottom 25%
  } else if (clampedPercentile <= 0.5) {
    // console.log("getLifeScoreEmoji - Emoji: 🧡 (Orange heart)");
    return "🧡"; // Orange heart for 25-50%
  } else if (clampedPercentile <= 0.75) {
    // console.log("getLifeScoreEmoji - Emoji: 💛 (Yellow heart)");
    return "💛"; // Yellow heart for 50-75%
  } else {
    // console.log("getLifeScoreEmoji - Emoji: 💚 (Green heart)");
    return "💚"; // Green heart for top 25%
  }
}

function createEmptyMetric(metricInfo) {
  return {
    ...metricInfo,
    value: null,
    percentileTag: null,
    tag_codes: [],
    color: null,
  };
}

export function calculatePercentileTag(percentile) {
  if (percentile >= 0.99) return "Top 1%";
  if (percentile >= 0.98) return "Top 2%";
  if (percentile >= 0.97) return "Top 3%";
  if (percentile >= 0.96) return "Top 4%";
  if (percentile >= 0.95) return "Top 5%";
  if (percentile >= 0.94) return "Top 6%";
  if (percentile >= 0.93) return "Top 7%";
  if (percentile >= 0.92) return "Top 8%";
  if (percentile >= 0.91) return "Top 9%";
  if (percentile >= 0.9) return "Top 10%";
  if (percentile >= 0.85) return "Top 15%";
  if (percentile >= 0.8) return "Top 20%";
  if (percentile >= 0.75) return "Top 25%";
  if (percentile >= 0.5) return "Above av.";
  if (percentile >= 0.25) return "Below av.";
  return "Bottom 25%";
}

export function generate_tag_codes(
  metricCode,
  percentageChange,
  bottleneckMetric,
  precision // Add precision parameter
) {
  const tagCodes = [];

  // console.log(
  //   "percentageChange",
  //   metricCode,
  //   percentageChange,
  //   bottleneckMetric,
  //   precision // Log precision
  // );

  // Check for increasing or decreasing trend
  if (Math.abs(percentageChange) >= 2) {
    if (percentageChange > 0) {
      tagCodes.push("increasing");
    } else {
      tagCodes.push("decreasing");
    }
  }

  // Check if limiting factor
  if (bottleneckMetric === metricCode) {
    tagCodes.push("limiting");
  }

  // Add precision tags based on the precision value
  if (precision !== undefined) {
    if (precision >= 0.85) {
      tagCodes.push("precision_high");
    } else if (precision < 0.6) {
      tagCodes.push("precision_low");
    }
  }

  // Apply the desired order
  const orderPriority = [
    "limiting",
    "increasing",
    "precision_high",
    "precision_low",
    "decreasing",
  ];

  return orderPriority.filter((tag) => tagCodes.includes(tag));
}

export const getMetricInsight = (tag_code) => {
  // console.log("tag_code", tag_code);
  const insightMap = {
    // increasing: {
    //   icon: faArrowTrendUp,
    //   text: "Increasing!",
    //   className: "increasing",
    // },
    // decreasing: {
    //   icon: faArrowTrendDown,
    //   text: "Decreasing",
    //   className: "decreasing",
    // },
    limiting: {
      icon: faTriangleExclamation,
      text: "Limiting!",
      className: "limiting",
    },
    precision_low: {
      icon: faWaveSquare,
      text: "Approximation",
      className: "precision-low",
    },
    precision_high: {
      text: "V. precise",
      icon: faBullseye,
      className: "precision-high",
    },
  };

  const insight = {
    text: insightMap[tag_code]["text"],
    className: insightMap[tag_code]["className"],
    icon: (
      <FontAwesomeIcon icon={insightMap[tag_code]["icon"]} className="icon" />
    ),
  };
  if (!insight) return null;

  return insight;
};

const data = {
  ftp: { "measure_batch_04-10-2024": { main_measure: null, measures: {} } },
  life_score: {
    "measure_batch_04-10-2024": {
      main_measure: "measure_04-10-2024",
      measures: {
        "measure_04-10-2024": {
          main_measure: true,
          model: "strava oct 2024",
          optional_details: {
            age_group: "30-39",
            bottleneck_metric: "stability_score",
            gender: "M",
            healthy_until: 63,
            life_expectancy: 78.2,
            metrics_used: ["v02_max", "stability_score"],
          },
          percentile: 0.99,
          precision: 0.9566596570644719,
          value: 67.797,
          workout_name: "",
        },
      },
    },
  },
  stability_score: {
    "measure_batch_04-10-2024": {
      main_measure: "measure_17-07-2024",
      measures: {
        "measure_16-07-2024": {
          main_measure: false,
          model: "stability_score_v3",
          optional_details: { sport_type: "Run", terrain_category: "Flat" },
          precision: 0.902,
          value: 0.86,
          workout_name: "Course à pied en soirée",
        },
        "measure_17-07-2024": {
          main_measure: true,
          model: "stability_score_v3",
          optional_details: { sport_type: "Run", terrain_category: "Flat" },
          precision: 0.9567777777777778,
          value: 1.34,
          workout_name: "Course à pied en soirée",
        },
        "measure_18-08-2024": {
          main_measure: false,
          model: "stability_score_v3",
          optional_details: { sport_type: "Run", terrain_category: "Flat" },
          precision: 0.914,
          value: 0.64,
          workout_name: "Course à pied dans l'après-midi",
        },
      },
    },
  },
  v02_max: {
    "measure_batch_04-10-2024": {
      main_measure: "measure_22-09-2024",
      measures: {
        "measure_22-09-2024": {
          decay_age: 90,
          main_measure: true,
          model: "cooper_strava_october_2024",
          optional_details: {},
          percentile: 0.99,
          precision: 0.9998765432098765,
          value: 57.3,
          workout_name: "Sortie vélo le matin",
        },
        "measure_27-07-2024": {
          decay_age: 90,
          main_measure: false,
          model: "cooper_strava_october_2024",
          optional_details: {},
          percentile: 0.7,
          precision: 0.9999922839506172,
          value: 48,
          workout_name: "Sortie vélo le midi",
        },
        "measure_28-04-2024": {
          decay_age: 90,
          main_measure: false,
          model: "cooper_strava_october_2024",
          optional_details: {},
          percentile: 0.98,
          precision: 0.9999922839506172,
          value: 56.7,
          workout_name: "VO2 max test",
        },
      },
    },
  },
};
