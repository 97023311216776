import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { BASE_URL } from "../config";
import "./LoadingProfile.scss";

const LoadingProfile = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [attempts, setAttempts] = useState(0);
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const stravaUsername = searchParams.get("strava_username");

  useEffect(() => {
    const checkProfileStatus = async () => {
      if (attempts >= 20) {
        setIsLoading(false);
        setError("Profile creation timed out. Please try again later.");
        return;
      }

      try {
        const response = await fetch(
          `${BASE_URL}/api/check_profile/${stravaUsername}`
        );
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const contentType = response.headers.get("content-type");
        if (!contentType || !contentType.includes("application/json")) {
          const text = await response.text();
          console.log("Unexpected response:", text);
          console.error("Unexpected response:", text);
          throw new TypeError(
            "Server did not return JSON. Check the console for details."
          );
        }
        const data = await response.json();
        if (data.status === "ready") {
          navigate(`/${data.username}`);
        } else {
          setAttempts((prevAttempts) => prevAttempts + 1);
          setTimeout(checkProfileStatus, 1000);
        }
      } catch (error) {
        console.error("Error checking profile status:", error);
        setIsLoading(false);
        setError(error.message);
      }
    };

    checkProfileStatus();
  }, [stravaUsername, navigate, attempts]);

  return (
    <div className="loading-profile">
      {isLoading ? (
        <>
          <h1>Building Your Profile</h1>
          <p>Please wait while we set up your account...</p>
          <div className="spinner"></div>
        </>
      ) : error ? (
        <div className="error-message">
          <p>There was an error creating your profile:</p>
          <p>{error}</p>
          <p>Please try again or contact support if the problem persists.</p>
        </div>
      ) : (
        <p>There was an error creating your profile. Please try again.</p>
      )}
    </div>
  );
};

export default LoadingProfile;
