import React from "react";
import {
  BarChart,
  Bar,
  LineChart,
  Line,
  XAxis,
  YAxis,
  ResponsiveContainer,
  Tooltip,
  CartesianGrid
} from "recharts";
import "./Graph.scss";

export const MinPerDay = ({ data , color = "#4A90E2", graphTitle = "Zone 2 Training, Past Week" }) => {
  // Return null if no data points
  if (!data || data.length < 1) return null;

  console.log('data from min-per-day-graph:', data);

  // The data should already have the correct 'day' property from metrics_processing_v3.js
  // No need to reformat the date here since it's already formatted correctly
  // Just use the data as is, since it already contains:
  // { date: "DD/MM/YYYY", day: "Mon", value: 123 }

  return (
    <div className="min-per-day-graph">
      <h3 className="graph-title">{graphTitle}</h3>
      <ResponsiveContainer width="100%" height={140}>
        <BarChart 
          data={data} 
          margin={{ top: 5, right: 0, left: 0, bottom: 25 }}
          background={{ fill: '#fff' }}
        >
          <CartesianGrid 
            strokeDasharray="3 3" 
            vertical={false}
          />
          <XAxis 
            dataKey="day" // Use the 'day' property which contains Mon, Tue, etc.
            axisLine={true}
            tickLine={true}
            tick={{ fontSize: 12 }}
            dy={5}
          />
          <YAxis 
            hide={false}
            axisLine={false}
            tickLine={true}
            tick={{ fontSize: 10 }}
            dx={-10}
          />
          <Tooltip 
            contentStyle={{ fontSize: '12px' }}
            formatter={(value) => [`${value} min`]}
            labelFormatter={(label) => label} // This will show the day name in the tooltip
          />
          <Bar 
            dataKey="value" 
            fill={color}
            radius={[4, 4, 0, 0]}
            barSize={20}
          />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export const MeasuresLineGraph = ({ data = [], color = "#4A90E2", graphTitle = "Measures Over Time", unit = "" }) => {
  // Return null if less than 3 data points
  if (!data || data.length < 3) return null;

  // Format dates consistently
  const formattedData = data.map(item => ({
    ...item,
    displayDate: new Date(item.date).toLocaleDateString('en-GB', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric'
    })
  }));

  return (
    <div className="measures-line-graph">
      <h3 className="graph-title">{graphTitle}</h3>
      <ResponsiveContainer width="100%" height={140}>
        <LineChart 
          data={formattedData}
          margin={{ top: 5, right: 0, left: 0, bottom: 25 }}
        >
          <CartesianGrid 
            strokeDasharray="3 3" 
            vertical={false}
          />
          <XAxis 
            dataKey="displayDate"
            axisLine={true}
            tickLine={true}
            tick={{ fontSize: 12 }}
            dy={5}
          />
          <YAxis 
            hide={false}
            axisLine={false}
            tickLine={true}
            tick={{ fontSize: 10 }}
            dx={-10}
          />
          <Tooltip 
            contentStyle={{ fontSize: '12px' }}
            formatter={(value) => [`${value}${unit ? ` ${unit}` : ''}`]}
            labelFormatter={(label) => label}
          />
          <Line 
            type="monotone"
            dataKey="value" 
            stroke={color}
            strokeWidth={2}
            dot={{ fill: color }}
          />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};

export default MinPerDay;