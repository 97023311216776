// utils/metricDetailsContent.js

import "./ExpandedWidget.scss";

import React from "react";
import {
  getLatestBatchFromMetric,
  getMainMeasurementFromLatestBatch,
  extractDatesAndValues,
  calculatePercentageChange,
} from "../utils/metrics_helper";
import { generate_tag_codes } from "../utils/profile_helper";
import MetricGraph from "./MetricGraph";

// JSON object with answers for each metric
const metricAnswers = {
  life_score: {
    measure:
      "Life Score measures the proportion of your life you expect to live in good health. ",
    longevity:
      "A higher Life Score indicates a greater share of your life spent in good health, contributing to a longer and more fulfilling lifespan.",
    calculation:
      "The score is calculated as the ratio of the number of healthy years you plan to live to your total life expectancy.",
    insights:
      "Your score reflects your current health status and lifestyle choices, indicating areas where you can improve your overall well-being.",
    improvement:
      "To improve your Life Score, focus on maintaining a balanced diet, regular physical activity, and mental well-being.",
  },
  v02_max: {
    description: (
      <>
        <p>
          <b>
            VO2 Max, short for maximal oxygen uptake, is the maximum rate at
            which your body can consume oxygen during intense exercise.
          </b>{" "}
          It represents the efficiency of your cardiorespiratory system in
          delivering oxygen to your muscles.
        </p>
        <p>
          Higher VO2 Max levels are correlated with reduced risks of
          cardiovascular diseases, type 2 diabetes, and certain cancers. It's
          also associated with improved cognitive function and a lower risk of
          dementia in older adults.
        </p>
      </>
    ),
    improvement: (
      <>
        <p>To improve your VO2 Max:</p>
        <ol>
          <li>Engage in regular high-intensity interval training (HIIT)</li>
          <li>Incorporate longer, steady-state cardio sessions</li>
          <li>Mix up your workouts to challenge different energy systems</li>
          <li>Ensure proper recovery and nutrition to support your training</li>
        </ol>
      </>
    ),
    goFurther: (
      <>
        <ul>
          <li>
            <a href="https://pubmed.ncbi.nlm.nih.gov/29293447/">
              Survival of the fittest: VO2max, a key predictor of longevity?
            </a>
          </li>

          <li>
            <a href="https://www.sciencedirect.com/science/article/abs/pii/S1440244018309198#">
              Effects of different protocols of high intensity interval training
              for VO2max improvements in adults
            </a>
          </li>
        </ul>
      </>
    ),
  },
  ftp: {
    description: (
      <>
        <p>
          <b>
            Functional Threshold Power (FTP) is the highest average power output
            you can sustain for approximately one hour on a bike.
          </b>{" "}
          It's typically measured in watts and represents your ability to
          maintain high-intensity effort over an extended period.
        </p>
        <p>
          A higher FTP indicates better cycling performance and overall
          endurance capacity. This improved cardiovascular fitness is associated
          with a lower risk of heart disease, better metabolic health, and
          increased longevity. Regular cycling at or near your FTP can also help
          maintain muscle mass and bone density as you age, contributing to a
          higher quality of life in later years.
        </p>
      </>
    ),
    improvement: (
      <>
        <p>To improve your FTP:</p>
        <ol>
          <li>
            Incorporate structured cycling workouts, including intervals and
            tempo rides
          </li>
          <li>Gradually increase the duration and intensity of your rides</li>
          <li>Include strength training exercises for your legs and core</li>
          <li>Ensure proper nutrition and recovery between workouts</li>
        </ol>
      </>
    ),
    goFurther: (
      <>
        <p>Consider these steps to further enhance your cycling performance:</p>
        <ul>
          <li>Invest in a power meter for more accurate FTP tracking</li>
          <li>
            Join cycling clubs or participate in group rides to challenge
            yourself
          </li>
          <li>
            Explore professional bike fitting services to optimize your cycling
            efficiency
          </li>
        </ul>
      </>
    ),
  },
  stability_score: {
    description: (
      <>
        <p>
          <b>
            Stability score measures your ability to maintain balance and
            control your body's position, particularly when standing or moving.
          </b>{" "}
          {/* It reflects the strength and coordination of your core muscles, as
          well as the efficiency of your proprioceptive system. */}
        </p>
        <p>
          Good stability is crucial for longevity as it significantly reduces
          the risk of falls and injuries, especially in older adults. It's also
          linked to better posture, reduced back pain, and improved overall
          physical function. Maintaining good stability throughout life can help
          preserve independence and quality of life in later years.
        </p>
      </>
    ),
    improvement: (
      <>
        <p>To improve your stability:</p>
        <ol>
          <li>
            Practice balance exercises like single-leg stands or yoga poses
          </li>
          <li>Incorporate core strengthening workouts into your routine</li>
          <li>Try stability ball exercises to challenge your balance</li>
          {/* <li>Include proprioception exercises to enhance body awareness</li> */}
        </ol>
      </>
    ),
    goFurther: (
      <>
        <p>Explore these additional ways to enhance your stability:</p>
        <ul>
          <li>
            Use specialized balance training equipment like balance boards or
            stability discs
          </li>
          <li>
            Take up activities that challenge balance, such as tai chi or
            stand-up paddleboarding
          </li>
          <li>
            Consult with a physical therapist for a personalized stability
            improvement program
          </li>
        </ul>
      </>
    ),
  },
  // Add more metrics as needed
};

const formatDate = (timestamp) => {
  if (!timestamp) return "N/A";
  const date = new Date(timestamp);
  return `${date.getDate().toString().padStart(2, "0")}/${(date.getMonth() + 1)
    .toString()
    .padStart(2, "0")}/${date.getFullYear()}`;
};

function generateAnalysisSentence(lifeScoreData) {
  if (!lifeScoreData || !lifeScoreData.measures) {
    return "Insufficient life score data to generate analysis.";
  }

  const latestMeasure = Object.values(lifeScoreData.measures)[0];
  const { optional_details } = latestMeasure;

  if (!optional_details) {
    return null;
  }

  const { bottleneck_metric, healthy_until, life_expectancy } =
    optional_details;

  if (
    bottleneck_metric === undefined ||
    healthy_until === undefined ||
    life_expectancy === undefined
  ) {
    return null;
  }

  const bottleneckCapitalized = bottleneck_metric
    .split("_")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");

  const healthyYears = Math.round(healthy_until);

  return (
    <>
      Your limiting metric is your <b>{bottleneckCapitalized}</b>. Your{" "}
      {bottleneckCapitalized} is projected to enter critical levels at age{" "}
      {healthyYears} - out of a life expectancy of {life_expectancy} years for
      your gender and age.
    </>
  );
}

const ExpandedWidget = ({ metric, userData }) => {
  // console.log("metric", JSON.stringify(metric, null, 2));
  // return null if metric.value is null
  if (metric.value === null) {
    return null;
  }
  const metricName = metric.real_name;
  const metricCode = metric.metric_code.toLowerCase();
  const userMetric = userData.metrics[metricCode];
  // console.log("userMetric", metricName, userMetric);

  if (!userMetric) {
    return <div>No data available for this metric.</div>;
  }

  const latestBatch = getLatestBatchFromMetric(userMetric);
  const latestBatchData = userMetric[latestBatch];
  const latestMeasure = getMainMeasurementFromLatestBatch(userMetric);

  const value = latestMeasure.value.toFixed(1);
  const percentileValue = latestMeasure.percentile * 100;
  const percentile =
    percentileValue > 50
      ? `Top ${(100 - percentileValue).toFixed(0)}%`
      : `Bottom ${percentileValue.toFixed(0)}%`;
  const calculatedAt = formatDate(latestMeasure.timestamp);
  const workoutUsed = latestMeasure.workout_name || null;

  const decayAge = latestMeasure.decay_age;

  const precisionValue =
    latestBatchData.measures["main_measure"]?.precision || 0;
  const precisionPercentage = (precisionValue * 100).toFixed(0);
  let precisionQuality = "";

  if (precisionValue < 0.6) {
    precisionQuality = "Low";
  } else if (precisionValue >= 0.6 && precisionValue <= 0.9) {
    precisionQuality = "Good";
  } else {
    precisionQuality = "High";
  }

  const precision = `${precisionPercentage}% (${precisionQuality})`;
  const dataSource = latestBatchData.data_source || "N/A";
  const methodology = workoutUsed;

  const tagCodes = generate_tag_codes(
    metricCode,
    userData.metrics,
    userData.metrics.life_score
  );
  const trend = tagCodes.includes("increasing")
    ? "Increasing"
    : tagCodes.includes("decreasing")
    ? "Decreasing"
    : "Stable";

  const answers = metricAnswers[metricCode] || {};

  let trendDescription = "Stable";

  const { percentageChange, formattedChange } =
    calculatePercentageChange(userMetric);

  if (percentageChange !== null) {
    trendDescription = formattedChange;
  }

  const metricTableData = {
    [`${metricName} value`]: value,
    "Pop. percentile": percentile,
    "Calculated on": calculatedAt,
    "Workout used": workoutUsed,
    Precision: precision,
    // Only include Trend if the metric is not life_score
    ...(metricCode !== "life_score" && { Trend: trendDescription }),
    // Add bottleneck metric for life_score
    ...(metricCode === "life_score" && {
      "Bottleneck metric": latestMeasure.optional_details?.bottleneck_metric
        ? latestMeasure.optional_details.bottleneck_metric
            .split("_")
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(" ")
        : "N/A",
    }),
    // Add Decay Age if it's available
    ...(decayAge !== undefined && { "Decay Age": decayAge }),
  };

  const Table = () => (
    <table className="metric-details-table">
      <tbody>
        {Object.entries(metricTableData).map(
          ([key, value]) =>
            value !== null &&
            value !== "" && (
              <tr key={key}>
                <td>{key}</td>
                <td>{value}</td>
              </tr>
            )
        )}
      </tbody>
    </table>
  );

  // console.log("metric", JSON.stringify(metric, null, 2));
  // console.log("userData", JSON.stringify(userData, null, 2));

  return (
    <div>
      {metricCode !== "life_score" ? (
        <>
          <h3>About your {metricName}</h3>

          <MetricGraph metric={metric} userData={userData} />

          {latestMeasure.limiting && (
            <p className="">
              {metricName} is your <i>limiting metric</i>. This means it's the
              metric that is limiting your Life Score, and the one you should
              prioritize working on to improve your overall health.
            </p>
          )}

          <Table />
        </>
      ) : null}

      {metricCode === "life_score" ? (
        <>
          <h3>About your Life Score</h3>
          <p>{generateAnalysisSentence(latestBatchData)}</p>
          <Table />

          <h3>What is the Life Score and how is it calculated?</h3>
          <p>
            <b>
              Your lifescore represents the proportion of your life projected to
              be spent in good health.{" "}
            </b>
            To perform everyday activities (such as climbing two flights of
            stairs or lifting a suitcase), you need adequate stability,
            strength, and cardiovascular fitness.{" "}
          </p>

          <p>
            Lifescore is calculated by dividing the number of years your health
            metrics are projected to remain at acceptable levels by the life
            expectancy for your age and gender group.
          </p>

          <p>
            This is done by applying discount rates to your current metrics.
          </p>
        </>
      ) : (
        <>
          <h3>What is {metricName} and how does it affect longevity?</h3>
          <p>{answers.description}</p>
        </>
      )}

      {metricCode !== "life_score" && (
        <>
          <h3>How to improve your {metricName}</h3>
          <p>{answers.improvement}</p>

          {/* <h3>How your {metricName} is calculated</h3> */}

          <h3>Research to go further</h3>
          <p>{answers.goFurther}</p>
        </>
      )}
    </div>
  );
};

export default ExpandedWidget;
