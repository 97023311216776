import React from "react";
import "./YearPicker.scss";

const YearPicker = ({
  onChange,
  defaultYear = 1985,
  startYear = 1960,
  endYear = new Date().getFullYear() - 18,
}) => {
  const years = Array.from(
    { length: endYear - startYear + 1 },
    (_, i) => endYear - i
  );

  const handleChange = (event) => {
    const selectedYear = parseInt(event.target.value, 10);
    onChange(selectedYear);
  };

  return (
    <div className="yearPicker">
      <select
        className="yearPicker__select"
        onChange={handleChange}
        defaultValue={defaultYear}
      >
        {years.map((year) => (
          <option key={year} value={year}>
            {year}
          </option>
        ))}
      </select>
    </div>
  );
};

export default YearPicker;
