// DEPRECATED AFTER v3 ? 


/**
 * Extracts the latest batch from a metric by looking at all measure_batch dates.
 * @param {Object} metric - The metric object containing measure batches.
 * @returns {string|null} The field name of the most recent batch, or null if no batches found.
 */
export function getLatestBatchFromMetric(metric) {
  if (!metric || typeof metric !== "object") {
    return null;
  }

  const batchDates = Object.keys(metric).filter((key) =>
    key.startsWith("measure_batch_")
  );

  if (batchDates.length === 0) {
    return null;
  }

  // Sort the batch dates in descending order (most recent first)
  const sortedBatchDates = batchDates.sort((a, b) => {
    const dateA = new Date(a.replace("measure_batch_", ""));
    const dateB = new Date(b.replace("measure_batch_", ""));
    return dateB - dateA;
  });

  // Return the most recent batch date
  return sortedBatchDates[0];
}

/**
 * Extracts dates and values from a bunch of measures.
 * @param {Object} measures - The measures object containing date-value pairs.
 * @returns {Array} An array of objects, each containing a date and value.
 */
export function extractDatesAndValues(measures) {
  if (!measures || typeof measures !== "object") {
    return [];
  }

  return Object.entries(measures)
    .map(([date, measureData]) => {
      // Extract the date from the measure key (e.g., "measure_22-09-2024")
      const extractedDate = date.replace("measure_", "");

      // Assuming the value is always in measureData.value
      // If the structure is different, adjust this line accordingly
      const value = measureData.value;

      return {
        date: extractedDate,
        value: value,
      };
    })
    .sort((a, b) => new Date(a.date) - new Date(b.date)); // Sort by date ascending
}

/**
 * Extracts the main measurement from the latest batch of a given metric.
 * @param {Object} metric - The metric object containing measure batches.
 * @returns {Object|null} The main measurement object, or null if not found.
 */
export function getMainMeasurementFromLatestBatch(metric) {
  if (!metric || typeof metric !== "object") {
    // console.log("getMainMeasurementFromLatestBatch: Invalid metric object");
    return null;
  }

  // Step 1: Get the latest batch
  const latestBatch = getLatestBatchFromMetric(metric);
  if (!latestBatch || !metric[latestBatch]) {
    // console.log("getMainMeasurementFromLatestBatch: No latest batch found");
    return null;
  }

  const batchData = metric[latestBatch];

  // Step 2: Directly access the main_measure
  if (!batchData.measures || !batchData.measures.main_measure) {
    console.log(
      "getMainMeasurementFromLatestBatch: No main_measure found in latest batch"
    );
    return null;
  }

  const mainMeasurement = batchData.measures.main_measure;

  // Step 3: Add the batch date to the main measurement
  return {
    ...mainMeasurement,
    batch_date: latestBatch.replace("measure_batch_", ""),
  };
}

export function calculatePercentageChange(metricData) {
  const latestBatch = getLatestBatchFromMetric(metricData);

  if (!latestBatch) {
    return { percentageChange: null, formattedChange: null };
  }

  const latestBatchData = metricData[latestBatch];

  if (
    !latestBatchData.measures ||
    typeof latestBatchData.measures !== "object"
  ) {
    // console.log("calculatePercentageChange: Invalid measures data");
    return { percentageChange: null, formattedChange: null };
  }

  const measures = Object.values(latestBatchData.measures);

  if (measures.length < 2) {
    // console.log(measures);
    // console.log(
    //   "calculatePercentageChange: Not enough measures for comparison"
    // );
    return { percentageChange: null, formattedChange: null };
  }

  // Sort measures by timestamp
  const sortedMeasures = measures.sort(
    (a, b) => new Date(a.timestamp) - new Date(b.timestamp)
  );

  const oldestMeasure = sortedMeasures[0];
  const newestMeasure = sortedMeasures[sortedMeasures.length - 1];

  const percentageChange =
    ((newestMeasure.value - oldestMeasure.value) / oldestMeasure.value) * 100;
  const roundedPercentageChange = Number(percentageChange.toFixed(2));

  // Calculate the time difference
  const oldestDate = new Date(oldestMeasure.timestamp);
  const newestDate = new Date(newestMeasure.timestamp);
  const timeDiff = newestDate - oldestDate;
  const daysDiff = Math.floor(timeDiff / (1000 * 60 * 60 * 24));
  const monthsDiff =
    (newestDate.getFullYear() - oldestDate.getFullYear()) * 12 +
    (newestDate.getMonth() - oldestDate.getMonth());

  let timeUnit, timeValue;
  if (monthsDiff >= 24) {
    timeValue = Math.floor(monthsDiff / 12);
    timeUnit = timeValue === 1 ? "year" : "years";
  } else if (monthsDiff >= 12) {
    timeValue = Math.floor(monthsDiff / 12);
    timeUnit = "year";
  } else if (daysDiff >= 30) {
    timeValue = monthsDiff;
    timeUnit = monthsDiff === 1 ? "month" : "months";
  } else {
    timeValue = Math.max(1, daysDiff); // Use 1 if daysDiff is 0
    timeUnit = timeValue === 1 ? "day" : "days";
  }

  const sign = roundedPercentageChange >= 0 ? "+" : "";
  const formattedChange = `${sign}${roundedPercentageChange}% in ${timeValue} ${timeUnit}`;

  return { percentageChange: roundedPercentageChange, formattedChange };
}

/**
 * Calculates the time since the last sync and formats it into a sentence.
 * @param {Object} metric - The metric object containing measure batches.
 * @returns {string} A formatted sentence describing the time since the last sync.
 */
export function getLastSyncTime(metric) {
  if (!metric || typeof metric !== "object") {
    return "Last sync unknown";
  }

  const latestBatch = getLatestBatchFromMetric(metric);
  if (!latestBatch || !metric[latestBatch] || !metric[latestBatch].timestamp) {
    return "Last sync unknown";
  }

  const lastSyncTimestamp = metric[latestBatch].timestamp;
  const lastSyncDate = new Date(lastSyncTimestamp);
  const now = new Date();
  const diffInMilliseconds = now - lastSyncDate;
  const diffInDays = diffInMilliseconds / (1000 * 60 * 60 * 24);

  if (diffInDays < 1) {
    return "Last sync today";
  } else if (diffInDays < 2) {
    return "Last sync yesterday";
  } else if (diffInDays < 30) {
    const days = Math.floor(diffInDays);
    return `Last sync ${days} days ago`;
  } else if (diffInDays < 365) {
    const months = Math.floor(diffInDays / 30);
    return `Last sync ${months} ${months === 1 ? "month" : "months"} ago`;
  } else {
    const years = Math.floor(diffInDays / 365);
    return `Last sync ${years} ${years === 1 ? "year" : "years"} ago`;
  }
}
